export const isProduction = process.env.REACT_APP_ENV === "production";
console.log("🚀 ~ Environment:", process.env.REACT_APP_ENV);

export const ENV = process.env.REACT_APP_ENV;
export const SENTRY_DSN =
	"https://7ea8933adc15f4ceb25c6653cd7366cf@o4504384688750592.ingest.us.sentry.io/4507842790293504";

export const CDN_URL = "https://d1ds5rzn7liqmq.cloudfront.net";
export const ASSETS_CDN = "https://d1ds5rzn7liqmq.cloudfront.net/biz";

export const WWW_URL = process.env.REACT_APP_WWW_URL || "https://dev.stampmyvisa.com/";
export const SHARE_QUESTIONNAIRE_URL =
	process.env.REACT_APP_SHARE_QUESTIONNAIRE_URL || "https://dev.travellers.stampmyvisa.com";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.qa.stampmyvisa.com";

// export const ASSETS_CDN = "";

// export const WWW_URL = process.env.REACT_APP_WWW_URL || "http://localhost:5000";
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";
// export const API_BASE_URL_V1 = process.env.REACT_APP_API_BASE_URL_V1 || "http://localhost:3001";
// export const SHARE_QUESTIONNAIRE_URL = process.env.REACT_APP_SHARE_QUESTIONNAIRE_URL ||  "http://localhost:3000";

